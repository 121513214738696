<template>
  <div style="height: 86vh">
    <el-form
      label-position="top"
      style="
        width: 30%;
        text-align: center;
        margin: 15px auto;
        background-color: white;
        border-radius: 4px;
        padding: 15px;
        box-shadow: rgb(200 200 200 / 50%) 0px 0px 10px;
      "
      :model="loginForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
    >
      <img style="width: 40%" alt="Vue logo" src="../assets/logo.png" />
      <h1>Войти в систему</h1>
      <el-form-item prop="login">
        <el-input
          v-model="loginForm.login"
          placeholder="Имя пользователя"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="loginForm.password"
          placeholder="Пароль"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="auth" type="primary"> Войти </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import notification from "@/mixins/notification";

export default {
  mixins: [notification],
  name: "loginForm",
  data() {
    return {
      loginForm: {
        login: "",
        password: "",
      },
      rules: {
        login: [
          {
            required: true,
            message: "Заполните это поле",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Заполните это поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    auth() {
      let data = {
        username: this.loginForm.login,
        password: this.loginForm.password,
      };
      this.$store
        .dispatch("auth", data)
        .catch((error) => {
          this.notification("Ошибка", error.response.data.message, "error");
        })
        .then(async (response) => {
          if (response.data.token !== undefined) {
            console.log(response.data);
            Cookies.set("eco-token", response.data.token, { expires: 7 });
            Cookies.set("userId", response.data.userId, { expires: 7 });
            Cookies.set("lvldostup", response.data.lvldostup, { expires: 7 });
            Cookies.set("prod", response.data.prod, { expires: 7 });
            if (
              response.data.userRole !== undefined &&
              response.data.userRole === "ADMIN"
            ) {
              Cookies.set("role", response.data.userRole, { expires: 7 });
              await this.$router.push("/organizations/main");
            } else {
              Cookies.set("role", response.data.userRole, { expires: 7 });
              await this.$router.push("/profile/info");
            }
            window.location.reload();
          }
        });
    },
  },
};
</script>

<style scoped></style>

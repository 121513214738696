<template>
  <div>
    <login-form></login-form>
  </div>
</template>

<script>
import LoginForm from "@/components/loginForm";
export default {
  name: "Login",
  components: { LoginForm },
};
</script>

<style scoped></style>
